import 'client-only';

export function trackEvent(event: string, properties?: Record<string, any>) {
  window.amplitude.track(event, properties);
}

export function trackPageView() {
  window.amplitude.track('web page viewed', JSON.parse(JSON.stringify(window.location)));
}

export function setUserId(userId: string) {
  window.amplitude.setUserId(userId);
}
