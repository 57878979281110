import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles/styles.module.scss';

type ListItemContentProps = {
  className?: string;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  label: ReactNode;
  description?: ReactNode;
  testId?: string;
};

const ListItemContent = ({ className, leftContent, rightContent, label, description, testId }: ListItemContentProps): JSX.Element => (
  <div className={classNames(styles.container, className)} data-testid={testId}>
    {leftContent}
    <div className={styles.textContent}>
      <div className={styles.label} title={typeof label === 'string' ? label : undefined}>
        {label}
      </div>
      {description && (
        <div className={styles.description} title={typeof description === 'string' ? description : undefined}>
          {description}
        </div>
      )}
    </div>
    {rightContent}
  </div>
);

export default ListItemContent;
