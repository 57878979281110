import classNames from 'classnames';
import type { LinkInfo, Size } from '../../types';
import LinkButtonOrDiv from '../LinkButtonOrDiv';
import styles from './styles/styles.module.scss';

type CustomAvatarProps = {
  bordered?: boolean;
  children: React.ReactNode;
  className?: string;
  linkInfo?: LinkInfo;
  onClick?: () => void;
  size?: Size<'xs' | 'sm' | 'md' | 'lg' | 'xl'>;
  testId: string;
  stopPropagation?: boolean;
};

// Used by the Avatar component and others to make an avatar-like component with arbitrary children
export default function CustomAvatar({
  bordered = false,
  children,
  className,
  linkInfo,
  onClick,
  size = 'md',
  testId,
  stopPropagation
}: CustomAvatarProps) {
  return (
    <LinkButtonOrDiv
      className={classNames(className, styles.customAvatar, styles[size], bordered && styles.bordered)}
      linkInfo={linkInfo}
      onClick={onClick}
      testId={testId}
      stopPropagation={stopPropagation}
    >
      {children}
    </LinkButtonOrDiv>
  );
}