import Icon from '../components/Icon';

const Hiking = Icon(
  'hiking',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 5.5a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5Zm0-4a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM6.702 21.08l.035.038L.83 22.46a.75.75 0 0 0 .34 1.46l22-5a.75.75 0 0 0-.34-1.46l-5.846 1.328a2 2 0 0 0-.164-1.038l-2.16-5.2v-.33l1.49 1.3a1.92 1.92 0 0 0 2.18.36 1.869 1.869 0 0 0 1-1.25A1.851 1.851 0 0 0 19 11.1l-3.16-4.25a2.77 2.77 0 0 0-2.2-1.1H8.28a1.76 1.76 0 0 0-1.75 1.71v4.23a1.75 1.75 0 0 0 1.75 1.75h.47l-2.42 5.32a2.12 2.12 0 0 0 .372 2.32Zm5.117-3.776 1.447 2.33-3.034.69 1.587-3.02Zm-.91-1.466L8.8 19.97a.63.63 0 0 1-.8.27.61.61 0 0 1-.31-.34.64.64 0 0 1 0-.47l2.3-5.07.92 1.478Zm-.409-3.36 3.96 6.362a.54.54 0 0 0 .72.18.55.55 0 0 0 .23-.69l-2.11-5.36a.67.67 0 0 1-.06-.28v-2.12a.76.76 0 0 1 1.25-.57l2.72 2.39c.14.12.34.24.51.15a.361.361 0 0 0 .19-.24.38.38 0 0 0-.07-.3l-3.2-4.25a1.27 1.27 0 0 0-1-.5l-3.14-.023v5.25ZM9 7.214l-.72-.005a.25.25 0 0 0-.28.25v4.23a.25.25 0 0 0 .25.25H9V7.215Z"
    />
    <path d="M14.124 23.777a.75.75 0 0 0 .636.143l8.41-1.91a.75.75 0 0 0-.34-1.46l-8.41 1.91a.75.75 0 0 0-.296 1.317Z" />
  </>
);

export default Hiking;
