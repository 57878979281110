import Icon from '../components/Icon';

const NationalPark = Icon(
  'national-park',
  <>
    <path d="M12.012 5.705H12zM11.361 6.284c.204-.481.391-.577.645-.579.254.002.44.098.645.58.456 1.093 1.484 1.851 2.45 2.563.384.268.466.82.221 1.144-.416.549-1.564.093-2.357-.71.177 1.466 1.486 2.577 2.534 3.256h.002c.893.545.107 1.924-.819 1.432a13 13 0 0 1-1.147-.708c-.276-.193-.654.013-.654.349v3.045a.15.15 0 0 1-.151.15h-1.451a.15.15 0 0 1-.151-.15V13.61c0-.336-.378-.542-.654-.35-.377.265-.766.5-1.147.709-.926.492-1.712-.886-.819-1.432 1.048-.677 2.354-1.787 2.533-3.25-.791.8-1.938 1.238-2.359.68-.283-.373-.154-.852.229-1.12.966-.71 1.993-1.469 2.45-2.564" />
    <path
      fillRule="evenodd"
      d="M11.627 21.787 12 22v.001l.374-.213c7.597-4.325 9.253-11.531 9.612-14.428a1.95 1.95 0 0 0-.48-1.537 1.98 1.98 0 0 0-1.476-.661h-1.506l.188-.444a1.95 1.95 0 0 0-.176-1.843A1.97 1.97 0 0 0 16.895 2h-9.79a1.97 1.97 0 0 0-1.641.875 1.95 1.95 0 0 0-.175 1.843l.188.444H3.97c-.565 0-1.103.241-1.477.661-.374.419-.548.98-.48 1.537.36 2.897 2.016 10.102 9.613 14.427M3.617 6.81a.47.47 0 0 1 .353-.157v.002h2.255c.337 0 .649-.167.835-.445a.99.99 0 0 0 .089-.937L6.67 4.141a.46.46 0 0 1 .042-.44.47.47 0 0 1 .392-.209h9.79a.468.468 0 0 1 .434.649l-.48 1.132a.99.99 0 0 0 .092.937c.186.277.497.443.833.443h2.256a.47.47 0 0 1 .467.524C20.17 9.804 18.69 16.266 12 20.276 5.309 16.265 3.829 9.803 3.503 7.177a.46.46 0 0 1 .114-.367"
      clipRule="evenodd"
    />
  </>
);

export default NationalPark;
