import { useEffect, useMemo, useState } from 'react';
import { Image } from '@alltrails/core';
import classNames from 'classnames';
import { getUserProfilePhoto } from '@alltrails/shared/utils/requests/photoRequests';
import { PhotoSize } from '@alltrails/shared/types/photos';
import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import ShimmerComponents from '@alltrails/shared/components/ShimmerComponents';
import type { LinkInfo, Size } from '../../types';
import PlusBadge from '../PlusBadge';
import CustomAvatar from './CustomAvatar';
import styles from './styles/styles.module.scss';

const { ProfilePhotoShimmer } = ShimmerComponents;

type AvatarProps = {
  baseUrl?: string;
  bordered?: boolean;
  className?: string;
  hasPlus?: boolean;
  eager?: boolean;
  linkInfo?: LinkInfo;
  onClick?: () => void;
  size?: Size<'xs' | 'sm' | 'md' | 'lg' | 'xl'>;
  testId: string;
  userId: number;
  userName: string;
  stopPropagation?: boolean;
};

export default function Avatar({
  baseUrl,
  bordered = false,
  className,
  hasPlus = false,
  eager = false,
  linkInfo,
  onClick,
  size = 'md',
  testId,
  userId,
  userName,
  stopPropagation
}: AvatarProps) {
  const [src, setSrc] = useState('');
  const [didError, setDidError] = useState(false);

  const photoSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return PhotoSize.Icon;
      case 'sm':
        return PhotoSize.SmallSquare;
      case 'lg':
      case 'xl':
        return PhotoSize.LargeSquare;
      case 'md':
      default:
        return PhotoSize.MediumSquare;
    }
  }, [size]);

  useEffect(() => {
    setSrc(getUserProfilePhoto(userId, photoSize, baseUrl));
  }, [userId, photoSize, baseUrl]);

  return (
    <CustomAvatar
      bordered={bordered}
      className={classNames(className, styles.userAvatar)}
      linkInfo={linkInfo}
      onClick={onClick}
      size={size}
      testId={testId}
      stopPropagation={stopPropagation}
    >
      {src === '' ? (
        <ShimmerWrapper>
          <ProfilePhotoShimmer className={styles[size]} />
        </ShimmerWrapper>
      ) : (
        <>
          <span className={styles.imageWrapper}>
            <Image
              className={styles.avatarPhoto}
              // Override the loader so we can explicitly just use the `src` as-is. This will ignore default quality and width arguments from Next. Our image API does not support them and will return a 400.
              loader={({ src }) => src}
              onError={() => setDidError(true)}
              src={didError ? '' : src}
              alt={userName}
              loading={eager ? 'eager' : 'lazy'}
              fill
            />
          </span>
          {hasPlus && size !== 'xs' && <PlusBadge size={size} className={styles.plusBadge} />}
        </>
      )}
    </CustomAvatar>
  );
}
