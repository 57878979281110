import Icon from '../components/Icon';

const Map = Icon(
  'map',
  <>
    <path
      fillRule="evenodd"
      d="M8.217 2.553a1.75 1.75 0 0 1 1.566 0l5.105 2.552-.019.038.02-.038a.25.25 0 0 0 .223 0l4.105-2.052.335.669-.335-.67a1.75 1.75 0 0 1 2.533 1.566v12.764a1.75 1.75 0 0 1-.967 1.565l-5 2.5-.114-.226.114.226a1.75 1.75 0 0 1-1.566 0l.114-.226-.114.226-5.105-2.553a.25.25 0 0 0-.224 0l-4.105 2.053a1.75 1.75 0 0 1-2.533-1.565V6.618c0-.663.374-1.269.967-1.565zm7.533 17.233 4.362-2.18a.25.25 0 0 0 .138-.224V4.618a.25.25 0 0 0-.362-.224l-4.105 2.053-.033.016zm-1.5-13.323v13.323l-4.467-2.233-.033-.016V4.213l4.467 2.234zm-6-2.25L3.888 6.394a.25.25 0 0 0-.138.224v12.764a.25.25 0 0 0 .362.223l4.105-2.052.033-.016z"
      clipRule="evenodd"
    />
  </>
);

export default Map;
