import { useCallback, useMemo, useState } from 'react';
import { AlgoliaResultType } from '@alltrails/shared/types/algoliaSearch';
import { useLocalStorage, removeItem } from '@alltrails/local-storage';
import { RecentHit } from '../types/algoliaResultTypes';

const storageKey = 'algolia-recent-searches';
const maxStored = 50;

const useRecentSearches = () => {
  const { getValue, setValue } = useLocalStorage<RecentHit[]>(storageKey, []);
  const [recentHits, setRecentHits] = useState(() => {
    const stored = getValue();
    if (stored) {
      // Validate stored items
      if (Array.isArray(stored)) {
        if (stored.find(item => !item || !item.type || !item.objectID)) {
          removeItem(storageKey);
        }
      }
    }
    return stored || [];
  });

  const updateRecentHits = useCallback((hits: RecentHit[]) => {
    if (typeof window !== 'undefined') {
      setValue(hits);
      setRecentHits(hits);
    }
  }, []);

  const getRecentHits = useCallback(
    (types: 'all' | AlgoliaResultType[], maxCount?: number) => {
      const filteredRecentHits = recentHits.filter(hit => types === 'all' || types.includes(hit.type));
      return maxCount ? filteredRecentHits.slice(0, maxCount) : filteredRecentHits;
    },
    [recentHits]
  );

  const addRecent = useCallback(
    (recentHit: RecentHit) => {
      if (recentHit) {
        const index = recentHits.findIndex(hit => hit && hit.objectID === recentHit.objectID);
        if (index > -1) {
          // Move the recent to the top of the list if it was already in the list
          updateRecentHits([recentHit, ...recentHits.slice(0, index), ...recentHits.slice(index + 1)]);
        } else {
          updateRecentHits([recentHit, ...recentHits.slice(0, maxStored - 1)]);
        }
      }
    },
    [recentHits, updateRecentHits]
  );

  const removeRecent = useCallback(
    (objectID: string) => {
      const index = recentHits.findIndex(hit => hit.objectID === objectID);
      if (index > -1) {
        updateRecentHits([...recentHits.slice(0, index), ...recentHits.slice(index + 1)]);
      }
    },
    [recentHits, updateRecentHits]
  );

  return useMemo(
    () => ({
      getRecentHits,
      addRecent,
      removeRecent
    }),
    [addRecent, getRecentHits, removeRecent]
  );
};

export default useRecentSearches;
