import Icon from '../components/Icon';

const Shield = Icon(
  'shield',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.88a.874.874 0 0 0-.46.152c-.3.182-1.685.994-3.464 1.638a31.639 31.639 0 0 1-3.66 1.081c-.377.082-.691.153-.977.438A1.5 1.5 0 0 0 3 6.25v4.012c0 3.875 1.797 6.685 3.738 8.562 1.923 1.859 4.024 2.842 4.792 3.096.305.105.636.104.94 0 .767-.255 2.868-1.238 4.792-3.097C19.203 16.946 21 14.136 21 10.26V6.25a1.5 1.5 0 0 0-1.414-1.497 18.824 18.824 0 0 1-3.547-1.07 63.43 63.43 0 0 1-3.615-1.68A.936.936 0 0 0 12 1.88ZM4.5 6.25v4.012c0 3.363 1.546 5.806 3.28 7.483 1.745 1.687 3.644 2.559 4.22 2.751.578-.194 2.476-1.066 4.22-2.752 1.734-1.677 3.28-4.12 3.28-7.484V6.25c-.452 0-.956-.19-1.387-.303-2.122-.557-4.123-1.49-6.082-2.463-1.94 1.114-4.058 1.888-6.214 2.465-.437.117-.873.218-1.317.301Z"
    />
  </>
);

export default Shield;
