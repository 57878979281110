import { getLocale, generateNewLanguageUrl } from '@alltrails/shared/utils/languageSupport';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { useCallback } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useRouter as usePageRouter } from '@alltrails/core';
import { getOsanoLocale } from '../utils/osano';

export default function useLanguageChangeHandler(fullPageReload = false) {
  const pathname = usePathname();
  const router = useRouter();
  const pageRouter = usePageRouter();

  return useCallback(
    async (newLanguage: LanguageRegionCode) => {
      const newLocale = getLocale(newLanguage);

      const href = generateNewLanguageUrl(pathname, newLanguage);

      if (fullPageReload) {
        // When routes are localized, we'll need to do a full page refresh to handle
        // the redirects/rewrites correctly.
        window.location.href = href;
      } else {
        if (pageRouter) {
          const { pathname, asPath, query } = pageRouter;
          pageRouter.push({ pathname, query }, asPath, { locale: newLocale });
        } else {
          router.push(href);
        }
      }

      // We're also setting the Osano script url based on locale in the _document file,
      // so this won't be necessary once we remove client-side routing for language selection.
      if (window.Osano?.cm) {
        window.Osano.cm.locale = getOsanoLocale(newLocale);
      }
    },
    [fullPageReload, pageRouter, pathname, router]
  );
}
