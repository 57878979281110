import { useMemo } from 'react';
import { AlgoliaIndexName, AlgoliaConfigs } from '@alltrails/shared/types/algoliaConfigs';
import { AlgoliaResultType } from '@alltrails/shared/types/algoliaSearch';
import AlgoliaIndex from '../utils/AlgoliaIndex';

// This is defined outside of the hook so that we can reuse the AlgoliaIndex instances across different callers
const indices: Record<string, AlgoliaIndex> = {};

const useAlgoliaIndex = (configs: AlgoliaConfigs, searchTypes: 'all' | 'pendingTrails' | AlgoliaResultType[]) => {
  const indexName = useMemo((): AlgoliaIndexName => {
    if (searchTypes === 'pendingTrails') {
      return 'pendingIndex';
    }
    if (searchTypes === 'all' || searchTypes.length === 0) {
      return 'primaryIndex';
    }
    if (searchTypes.includes('map') || searchTypes.includes('track')) {
      return 'mapsIndex';
    }
    return 'primaryIndex';
  }, [searchTypes]);

  const index = useMemo(() => {
    const indexId = configs.indexes[indexName].id;
    if (!indices[indexId]) {
      const { application } = configs.indexes[indexName];
      const { apiKey, applicationId } = configs.applications[application];
      indices[indexId] = new AlgoliaIndex(applicationId, apiKey, indexId);
    }
    return indices[indexId];
  }, [configs, indexName]);

  return index;
};

export default useAlgoliaIndex;
