'use client';

import { type FooterLinksType } from '../../generateFooterLinks';
import styles from './styles.module.scss';

const DesktopLinks = ({ href, title, testId, amplitudeEventName, childLinks = [] }: FooterLinksType) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <a
        href={href}
        data-testid={testId}
        onClick={() => {
          if (amplitudeEventName) {
            window.amplitude.track(amplitudeEventName);
          }
        }}
      >
        {title}
      </a>
    </div>
    <ul>
      {childLinks.map(link => (
        <li key={link.title} className={styles.childLink}>
          <a
            data-testid={link.testId}
            href={link.href}
            {...(link.openInNewWindow ? { target: '_blank' } : {})}
            onClick={() => {
              if (link.amplitudeEventName) {
                window.amplitude.track(link.amplitudeEventName);
              }
            }}
          >
            {link.title}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default DesktopLinks;
