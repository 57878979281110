import Icon from '../components/Icon';

const ArrowExternal = Icon(
  'arrow-external',
  <>
    <path d="M18 5.25H8.25a.75.75 0 0 0 0 1.5h7.94L5.47 17.47a.75.75 0 1 0 1.06 1.06L17.25 7.81v7.94a.75.75 0 0 0 1.5 0V6c0-.39-.346-.75-.75-.75" />
  </>
);

export default ArrowExternal;
