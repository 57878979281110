import { useIntl } from 'react-intl';
import { Image } from '@alltrails/core';
import { useLanguageRegionCode } from '@alltrails/language';

type AppleLaurelProps = { height: number };

const AppleLaurel = ({ height }: AppleLaurelProps) => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();

  const src = require(`./laurels/apple-laurel-${languageRegionCode}.svg`);

  return <Image alt={intl.formatMessage({ defaultMessage: 'Apple App Store App of the Year' })} src={src} height={height} />;
};

export default AppleLaurel;
