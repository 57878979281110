import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export default function startClientBugsnag() {
  if (process.env.NEXT_PUBLIC_BUGSNAG_KEY) {
    Bugsnag.start({
      appType: 'client',
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
      appVersion: process.env.NEXT_PUBLIC_GIT_COMMIT,
      releaseStage: process.env.NEXT_PUBLIC_DEPLOY_NAMESPACE,
      plugins: [new BugsnagPluginReact()]
    });
  }
}
