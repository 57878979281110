import StarRating from '@alltrails/denali/components/StarRating';
import { ComponentProps } from 'react';

type AppStarRatingProps = Pick<ComponentProps<typeof StarRating>, 'size'>;

const AppStarRating = ({ size }: AppStarRatingProps) => {
  return <StarRating rating={4.8} ratingSide="left" shouldShowRating size={size} />;
};

export default AppStarRating;
