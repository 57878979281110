import Icon from '../components/Icon';

const Settings = Icon(
  'settings',
  <>
    <path
      fillRule="evenodd"
      d="M12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5M9.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M10.757 2.235C9.023.323 5.847 1.638 5.974 4.216l.022.471a1.25 1.25 0 0 1-1.309 1.31l-.471-.023c-2.578-.126-3.893 3.05-1.981 4.783l.349.317a1.25 1.25 0 0 1 0 1.852l-.35.317c-1.911 1.734-.596 4.91 1.982 4.783l.471-.022a1.25 1.25 0 0 1 1.31 1.309l-.024.471c-.125 2.578 3.05 3.893 4.784 1.982l.317-.35a1.25 1.25 0 0 1 1.852 0l.317.35c1.734 1.911 4.909.596 4.783-1.982l-.023-.471a1.25 1.25 0 0 1 1.31-1.31l.47.023c2.579.126 3.894-3.049 1.982-4.783l-.35-.317a1.25 1.25 0 0 1 0-1.852l.35-.317c1.912-1.734.597-4.909-1.981-4.783l-.471.023a1.25 1.25 0 0 1-1.31-1.31l.023-.47c.126-2.579-3.05-3.894-4.783-1.982l-.317.35a1.25 1.25 0 0 1-1.852 0zM7.472 4.143c-.057-1.172 1.386-1.77 2.174-.9l.317.349a2.75 2.75 0 0 0 4.074 0l.317-.35c.788-.868 2.231-.27 2.174.901l-.023.471a2.75 2.75 0 0 0 2.88 2.88l.472-.022c1.172-.057 1.77 1.386.9 2.174l-.349.317a2.75 2.75 0 0 0 0 4.074l.35.317c.869.788.27 2.231-.901 2.174l-.471-.023a2.75 2.75 0 0 0-2.88 2.88l.022.472c.057 1.172-1.386 1.77-2.174.9l-.317-.349a2.75 2.75 0 0 0-4.074 0l-.317.35c-.788.869-2.231.27-2.174-.9l.023-.472a2.75 2.75 0 0 0-2.88-2.88l-.472.022c-1.172.057-1.77-1.386-.9-2.174l.349-.317a2.75 2.75 0 0 0 0-4.074l-.35-.317c-.869-.788-.27-2.231.901-2.174l.471.023a2.75 2.75 0 0 0 2.88-2.88z"
      clipRule="evenodd"
    />
  </>
);

export default Settings;
