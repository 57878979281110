import Icon from '../components/Icon';

const Help = Icon(
  'help',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.15 18.938A9.719 9.719 0 0 0 12 21.75a9.719 9.719 0 0 0 6.938-2.9A9.719 9.719 0 0 0 21.75 12a9.719 9.719 0 0 0-2.9-6.939A9.719 9.719 0 0 0 12 2.25a9.719 9.719 0 0 0-6.849 2.81.763.763 0 0 0-.09.091A9.719 9.719 0 0 0 2.25 12a9.719 9.719 0 0 0 2.9 6.938ZM3.75 12c0-2.008.717-3.849 1.91-5.28l2.672 2.673A4.48 4.48 0 0 0 7.5 12c0 .972.308 1.872.832 2.607L5.66 17.28A8.216 8.216 0 0 1 3.75 12Zm2.97 6.34 2.673-2.672A4.48 4.48 0 0 0 12 16.5a4.48 4.48 0 0 0 2.607-.832l2.672 2.672A8.217 8.217 0 0 1 12 20.25a8.217 8.217 0 0 1-5.28-1.91Zm11.62-1.06-2.672-2.673A4.48 4.48 0 0 0 16.5 12a4.48 4.48 0 0 0-.832-2.607L18.34 6.72A8.217 8.217 0 0 1 20.25 12a8.217 8.217 0 0 1-1.91 5.28ZM17.28 5.66l-2.673 2.672A4.48 4.48 0 0 0 12 7.5a4.48 4.48 0 0 0-2.607.832L6.72 5.66A8.217 8.217 0 0 1 12 3.75c2.008 0 3.849.717 5.28 1.91ZM9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
    />
  </>
);

export default Help;
