import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import logConsentDecisionMade from '@alltrails/analytics/events/logConsentDecisionMade';
import Cookies from 'js-cookie';
import { setUserProperties } from '@alltrails/amplitude';

const setAppsflyerMeasurement = (consentObject: OsanoConsentObject) => {
  if (consentObject.ANALYTICS === 'ACCEPT') {
    window?.AF_SDK?.PLUGINS?.PBA?.enableMeasurement();
  } else {
    window?.AF_SDK?.PLUGINS?.PBA?.disableMeasurement();
  }
};

// Track Osano consent decisions
const useOsano = () => {
  useEffect(() => {
    const setConsentUserProperty = (consentObject?: OsanoConsentObject) => {
      setUserProperties({ ...consentObject });
    };

    const handleOsanoInit = (consentObject?: OsanoConsentObject) => {
      setConsentUserProperty(consentObject);
    };

    const handleOsanoConsentSaved = (consentObject: OsanoConsentObject) => {
      const consent = Cookies.get('at_osano');
      const stringConsent = JSON.stringify({ consent: consentObject });

      if (consent === stringConsent) return;
      setConsentUserProperty(consentObject);

      logConsentDecisionMade({
        analytics: consentObject.ANALYTICS,
        essential: consentObject.ESSENTIAL,
        marketing: consentObject.MARKETING,
        optout: consentObject.OPT_OUT,
        personalization: consentObject.PERSONALIZATION,
        storage: consentObject.STORAGE
      });

      datadogRum.setTrackingConsent(consentObject.ANALYTICS === 'ACCEPT' ? 'granted' : 'not-granted');
      setAppsflyerMeasurement(consentObject);

      Cookies.set('at_osano', stringConsent);
    };

    window.Osano?.cm?.addEventListener('osano-cm-consent-saved', handleOsanoConsentSaved);
    window.Osano?.cm?.addEventListener('osano-cm-initialized', handleOsanoInit);

    return () => {
      window.Osano?.cm?.removeEventListener('osano-cm-consent-saved', handleOsanoConsentSaved);
      window.Osano?.cm?.removeEventListener('osano-cm-initialized', handleOsanoInit);
    };
  }, []);
};

export default useOsano;
