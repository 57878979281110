import Icon from '../components/Icon';

const PlaceTrail = Icon(
  'place-trail',
  <>
    <path d="M14 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
    <path
      fillRule="evenodd"
      d="M12 1.25A8.75 8.75 0 0 0 3.25 10c0 2.75 1.51 5.463 3.155 7.624 1.662 2.183 3.555 3.923 4.504 4.743l.04.031a1.75 1.75 0 0 0 2.142-.031c.949-.82 2.842-2.56 4.504-4.743 1.644-2.16 3.155-4.875 3.155-7.624A8.75 8.75 0 0 0 12 1.25M6.873 4.873A7.25 7.25 0 0 1 19.25 10c0 2.244-1.259 4.626-2.849 6.715-1.558 2.048-3.346 3.699-4.265 4.494a.25.25 0 0 1-.272 0c-.92-.795-2.707-2.446-4.265-4.494C6.009 14.626 4.75 12.244 4.75 10a7.25 7.25 0 0 1 2.123-5.127"
      clipRule="evenodd"
    />
  </>
);

export default PlaceTrail;
