import Icon from '../components/Icon';

const TrailDog = Icon(
  'trail-dog',
  <>
    <path d="M23.42 6a1.73 1.73 0 0 0-1.39-.71 1.7 1.7 0 0 1-1.26-.48 2.37 2.37 0 0 0-1.82-1.11h-1.3a2.09 2.09 0 0 0-2 1.18l-1.71 3.66L7.65.48a.78.78 0 0 0-1-.13.763.763 0 0 0-.13 1.06l6.03 7.78H7.49c-4.112 0-4.873.83-7.09 4.233a.73.73 0 0 0 .217 1.013.754.754 0 0 0 1.04-.24c.564-.886 1.2-1.724 1.903-2.506-.05 2.46-.27 2.84-1 3.76-.91 1.21-1.43 1.72-1.47 1.77a2.6 2.6 0 0 0-.6 1.67v4.05a.75.75 0 0 0 .216.533c.14.14.336.22.534.217h3.61a.75.75 0 0 0 .687-.429.75.75 0 0 0-.117-.801l-.87-1v-.55c.002-.72.25-1.55.77-2.07l2.21 4.5a.75.75 0 0 0 .68.42h3.06a.72.72 0 0 0 .66-.4.76.76 0 0 0 0-.78l-.83-1.17-1-1.78a1.24 1.24 0 0 1 0-1l1.74-1.82 1.23.55v.11L14.23 19c.177.255.27.559.27.87v3.08a.75.75 0 0 0 .75.75h3.4a.75.75 0 0 0 .67-.41.77.77 0 0 0-.06-.78l-.43-.58c-.17-.25-.2-.33-.2-.49v-9.07l1.16-2.64h1a2.45 2.45 0 0 0 2.31-1.29c.178-.44.366-.875.53-1.32A1.24 1.24 0 0 0 23.42 6M4.2 17.77a4.7 4.7 0 0 0-1.15 3.1v.82c0 .177.06.35.17.49H1.99v-3.3c-.001-.24.072-.474.21-.67q.828-.87 1.55-1.83c1.11-1.38 1.3-2.18 1.31-5.52.801-.143 1.616-.2 2.43-.17h6.38l3.25 1.94v4a.49.49 0 0 1-.21.33.9.9 0 0 1-.63.17l-4.34-1.94a7.14 7.14 0 0 0-6.31 1.3zm4.71-.16a.6.6 0 0 0-.12.18 2.75 2.75 0 0 0 0 2.44l1 1.89v.07H8.65l-2.2-4.53c1.053-.843 2.33-1.328 3.69-1.3zM16 22.19v-2.33a3 3 0 0 0-.35-1.4h.09a2.25 2.25 0 0 0 1.38 0v3a2 2 0 0 0 .14.76zm5.65-14.3a1.17 1.17 0 0 1-.91.34h-1.45a.75.75 0 0 0-.69.44l-1.06 2.42-2.47-1.44 2-4.13a.6.6 0 0 1 .243-.246c.43-.237 1.168-.074 1.637-.074.09 0 .34.1.64.49.304.346.68.62 1.101.805.454.2.927.265 1.419.265-.138.385-.29.758-.46 1.13" />
  </>
);

export default TrailDog;
