import { FONT_WEIGHT_BOLD } from '@alltrails/denali/tokens';

function getBoldedTextElements(text: string, query: string): React.ReactNode[] {
  if (!query) {
    return [text];
  }

  const elements: React.ReactNode[] = [];
  let currentIndex = 0;

  while (currentIndex < text.length) {
    const matchIndex = text.toLowerCase().indexOf(query.toLowerCase(), currentIndex);

    if (matchIndex === -1) {
      elements.push(<span key={currentIndex}>{text.slice(currentIndex)}</span>);
      break;
    }

    if (matchIndex > currentIndex) {
      elements.push(<span key={currentIndex}>{text.slice(currentIndex, matchIndex)}</span>);
    }

    elements.push(
      <span key={matchIndex} style={{ fontWeight: FONT_WEIGHT_BOLD }}>
        {text.slice(matchIndex, matchIndex + query.length)}
      </span>
    );
    currentIndex = matchIndex + query.length;
  }

  return elements;
}

export default getBoldedTextElements;
