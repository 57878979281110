import { MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import { InputBaseProps, Size } from '../../types';
import ChevronSm from '../../icons/ChevronSm';
import InputField from '../InputField';
import styles from './styles/styles.module.scss';

export type SelectProps = InputBaseProps & {
  className?: string;
  hasError?: boolean; // Used to display error styling when the error text is displayed outside of the component
  onChange: (value: string) => void;
  onClick?: MouseEventHandler<HTMLSelectElement>;
  options: { label: ReactNode; value: string }[];
  placeholder: string;
  required?: boolean;
  size?: Size<'sm' | 'md'>;
  testId: string;
  value?: string;
};

const Select = ({
  className,
  disabled,
  hasError,
  errorText,
  helperText,
  hideLabel,
  labelText,
  labelTextVariant,
  onChange,
  onClick,
  options,
  placeholder,
  required = false,
  size = 'md',
  testId,
  value
}: SelectProps): JSX.Element => {
  return (
    <InputField
      className={className}
      disabled={disabled}
      errorText={errorText}
      helperText={helperText}
      labelText={hideLabel ? '' : labelText}
      labelTextVariant={labelTextVariant}
      size={size}
    >
      <div className={styles.selectContainer}>
        <select
          aria-label={hideLabel ? labelText : undefined}
          className={classNames(styles.select, styles[size], { [styles.placeholder]: !value, [styles.hasError]: hasError || errorText })}
          disabled={disabled}
          onChange={e => {
            onChange(e.target.value);
          }}
          onClick={onClick}
          required={required}
          value={value}
          data-testid={testId}
        >
          <option key="select-placeholder" value="" disabled>
            {placeholder}
          </option>
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        <div className={classNames(styles.chevron, styles[size])}>
          <ChevronSm size={size} color={disabled ? '--color-text-disabled' : '--color-text-secondary'} orientation="down" />
        </div>
      </div>
    </InputField>
  );
};

export default Select;
