import Icon from '../components/Icon';

const Chevron = Icon(
  'chevron',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.47 6.62a.75.75 0 0 1 1.06 0l8.201 8.2a.75.75 0 0 1-1.06 1.06L12 8.21l-7.67 7.67a.75.75 0 1 1-1.061-1.06l8.2-8.2Z"
    />
  </>
);

export default Chevron;
