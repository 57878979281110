import Icon from '../components/Icon';

const QrCode = Icon(
  'qr-code',
  <>
    <path d="M6.75 6.75h1.5v1.5h-1.5z" />
    <path
      fillRule="evenodd"
      d="M5.25 3.75a1.5 1.5 0 0 0-1.5 1.5v4.5a1.5 1.5 0 0 0 1.5 1.5h4.5a1.5 1.5 0 0 0 1.5-1.5v-4.5a1.5 1.5 0 0 0-1.5-1.5zm0 1.5h4.5v4.5h-4.5z"
      clipRule="evenodd"
    />
    <path d="M8.25 15.75h-1.5v1.5h1.5z" />
    <path
      fillRule="evenodd"
      d="M5.25 12.75a1.5 1.5 0 0 0-1.5 1.5v4.5a1.5 1.5 0 0 0 1.5 1.5h4.5a1.5 1.5 0 0 0 1.5-1.5v-4.5a1.5 1.5 0 0 0-1.5-1.5zm0 1.5h4.5v4.5h-4.5z"
      clipRule="evenodd"
    />
    <path d="M17.25 6.75h-1.5v1.5h1.5z" />
    <path
      fillRule="evenodd"
      d="M12.75 5.25a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5zm6 0h-4.5v4.5h4.5z"
      clipRule="evenodd"
    />
    <path d="M14.25 13.5a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0z" />
    <path d="M16.5 12.75a.75.75 0 0 1 .75.75v.75h2.25a.75.75 0 0 1 0 1.5h-2.25v3.75a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1 0-1.5h2.25V13.5a.75.75 0 0 1 .75-.75M19.5 17.25a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0V18a.75.75 0 0 1 .75-.75" />
  </>
);

export default QrCode;
