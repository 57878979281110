import Icon from '../components/Icon';

const PlacePark = Icon(
  'place-park',
  <>
    <path
      fillRule="evenodd"
      d="M18.615 13.635c1.785.989 1.095 3.906-.937 4.103-.62.09-1.319-.246-1.58-.446l-1.772-1.082V21a1 1 0 0 1-1 1l-2.624.001a1 1 0 0 1-1-.997l-.015-4.798-2.009 1.237c-2.625 1.454-4.776-2.517-2.07-3.951 1.242-.916 2.913-2.195 3.736-3.539-.145.1-.545.445-.703.536-1.672 1.164-3.905.08-3.448-2.091.415-1.478 2.093-1.98 3.028-3.01.835-.75 1.531-1.545 1.96-2.55.39-1.2 1.79-1.963 2.91-1.092.497.428.625.844.873 1.36.928 1.962 2.864 2.976 4.435 4.418.646.78.656 1.995.008 2.779-.929 1.006-2.09.793-3.124.186-.114-.067-.476-.388-.586-.464.885 1.329 2.646 2.736 3.919 3.612zM13.212 8.55c.322 2.752 2.308 4.97 4.603 6.354.738.374.135 1.569-.569 1.295-.137-.076-.276-.142-.41-.226l-1.806-1.116c-.934-.565-2.23.188-2.226 1.269-.007 1.653-.008 4.337-.008 4.337l-1.576-.002-.005-4.276c.033-1.071-1.191-1.885-2.179-1.362L6.953 16.11c-.256.138-.397.147-.569.103-.438-.122-.724-.861-.338-1.188 1.552-1.047 3.114-2.203 3.943-3.948a7.1 7.1 0 0 0 .858-2.51.553.553 0 0 0-.963-.442c-.725.828-2.307 2.104-3.105 1.23a.72.72 0 0 1 .025-.88C8.707 6.9 10.724 5.608 11.685 3.2c.134-.208.133-.312.327-.315.15-.014.249.145.33.315 1.04 2.157 2.613 3.594 4.53 4.989.75.394.631 1.314-.1 1.417-1.045.036-1.99-.868-2.79-1.562-.36-.323-.896.05-.77.507"
      clipRule="evenodd"
    />
  </>
);

export default PlacePark;
