import { useIntl } from 'react-intl';
import { Image } from '@alltrails/core';
import QRCodeSvg from './QRCode.svg';

type QRCodeProps = { size: number };

const QRCode = ({ size }: QRCodeProps) => {
  const intl = useIntl();

  return (
    <Image
      alt={intl.formatMessage({
        defaultMessage:
          'This QR code is intended to scan from a smartphone. If you have the AllTrails app, the link will open the app for you. If you don’t have the app, the link will take you to your device’s app store.'
      })}
      src={QRCodeSvg}
      loading="lazy"
      width={size}
      height={size}
    />
  );
};

export default QRCode;
