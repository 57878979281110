/* eslint-disable @next/next/no-img-element */
import Filter from '@alltrails/denali/icons/Filter';
import Hiking from '@alltrails/denali/icons/Hiking';
import TrailDog from '@alltrails/denali/icons/TrailDog';
import List from '@alltrails/denali/icons/List';
import Map from '@alltrails/denali/icons/Map';
import Park from '@alltrails/denali/icons/PlacePark';
import Place from '@alltrails/denali/icons/Place';
import Record from '@alltrails/denali/icons/Record';
import Shield from '@alltrails/denali/icons/Shield';
import Trail from '@alltrails/denali/icons/PlaceTrail';
import Waterfall from '@alltrails/denali/icons/PlaceWaterfall';
import Mountain from '@alltrails/denali/icons/PlaceMountain';
import Photo from '@alltrails/denali/icons/Photo';
import Location from '@alltrails/denali/icons/Location';
import { AlgoliaSearchResult, NearbyResult } from '../../types/algoliaResultTypes';

export type ResultIconProps = {
  result: AlgoliaSearchResult | NearbyResult;
};

const ResultIcon = ({ result }: ResultIconProps) => {
  switch (result.type) {
    case 'area':
      return result.area_type === 'N' ? <Shield color="--color-text-secondary" /> : <Park color="--color-text-secondary" />;
    case 'filter':
      if (result.filters?.activity?.includes('hiking')) {
        return <Hiking color="--color-text-secondary" />;
      }
      if (result.filters?.suitability?.includes('dogs')) {
        return <TrailDog color="--color-text-secondary" />;
      }
      if (result.filters?.feature?.includes('waterfall')) {
        return <Waterfall color="--color-text-secondary" />;
      }
      return <Filter color="--color-text-secondary" />;
    case 'guide':
      return <img src={`${result.photo_url}?size=96w96h`} alt="" data-testid={`guide-result-image-${result.ID}`} />;
    case 'list':
      return <List color="--color-text-secondary" />;
    case 'map':
      return <Map color="--color-text-secondary" />;
    case 'nearby':
      return <Location color="--color-text-secondary" />;
    case 'page':
      return <img src={result.image_url} alt="" data-testid={`page-result-image-${result.ID}`} />;
    case 'place':
      return <Place color="--color-text-secondary" />;
    case 'poi':
      switch (result.subtype) {
        case 'peak':
          return <Mountain color="--color-text-secondary" />;
        case 'waterfall':
          return <Waterfall color="--color-text-secondary" />;
        default:
          return <Photo color="--color-text-secondary" />;
      }
    case 'track':
      return <Record color="--color-text-secondary" />;
    case 'trail':
      return <Trail color="--color-text-secondary" />;
    default:
      return null;
  }
};

export default ResultIcon;
