import { ComponentProps } from 'react';
import classNames from 'classnames';
import Popover from '../Popover/Popover';
import { DropdownMenuOption, DropdownMenuSectionOption, DropdownMenuTextOption } from '../../types';
import styles from './styles/styles.module.scss';
import DropdownMenuItem from './DropdownMenuItem';

const isDropdownMenuTextOption = (option: DropdownMenuOption): option is DropdownMenuTextOption => 'title' in option;
const isDropdownMenuSectionOption = (option: DropdownMenuOption): option is DropdownMenuSectionOption => 'sectionHeader' in option;

type DropdownMenuProps = Pick<
  ComponentProps<typeof Popover>,
  'anchor' | 'className' | 'containerMouseHandlers' | 'isOpen' | 'onCloseRequest' | 'placement' | 'returnFocusOnDeactivate' | 'testId'
> & {
  options: DropdownMenuOption[];
};

const DropdownMenu = ({ options, ...popoverProps }: DropdownMenuProps): JSX.Element => (
  <Popover {...popoverProps} className={styles.popover}>
    {/* This inner div is necessary to allow border-radius to cover the scrollbar */}
    <div className={classNames(styles.container, popoverProps.className)}>
      {options.map((option, index) => {
        const key = `option-${index}`;
        if (isDropdownMenuTextOption(option)) {
          return <DropdownMenuItem key={key} option={option} textVariant="text100" />;
        }
        if (isDropdownMenuSectionOption(option)) {
          return (
            <div key={key} className={styles.sectionOption}>
              <div key={key} className={styles.sectionDivider} />
              {option.sectionHeader ? <span className={styles.sectionHeader}>{option.sectionHeader}</span> : null}
            </div>
          );
        }
        return option;
      })}
    </div>
  </Popover>
);

export default DropdownMenu;
