import Icon from '../components/Icon';

const List = Icon(
  'list',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 6A1.125 1.125 0 1 1 3 6a1.125 1.125 0 0 1 2.25 0Zm3-.75a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5h-12Zm0 6a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5h-12ZM7.5 18a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 7.5 18Zm-3.375-4.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM5.25 18A1.125 1.125 0 1 1 3 18a1.125 1.125 0 0 1 2.25 0Z"
    />
  </>
);

export default List;
