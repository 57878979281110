import Icon from '../components/Icon';

const Mobile = Icon(
  'mobile',
  <>
    <path
      fillRule="evenodd"
      d="M5.25 3.75A2.25 2.25 0 0 1 7.5 1.5h9a2.25 2.25 0 0 1 2.25 2.25v16.5a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25zM7.5 3a.75.75 0 0 0-.75.75v16.5c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75V3.75A.75.75 0 0 0 16.5 3zM9 5.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5A.75.75 0 0 1 9 5.25m1.876 13.375a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0"
      clipRule="evenodd"
    />
  </>
);

export default Mobile;
