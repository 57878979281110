import Icon from '../components/Icon';

const Shop = Icon(
  'shop',
  <>
    <path
      fillRule="evenodd"
      d="M5.063 3h13.875c.651 0 1.262.46 1.442 1.088l1.337 4.69q.032.107.033.222v1.5a3.75 3.75 0 0 1-1.5 3v6c0 .817-.683 1.5-1.5 1.5H5.25c-.817 0-1.5-.683-1.5-1.5v-6a3.75 3.75 0 0 1-1.5-3V9q0-.113.034-.223l1.34-4.69A1.53 1.53 0 0 1 5.06 3zM18 12.75a2.25 2.25 0 0 1-2.25-2.25v-.75h4.5v.75a2.25 2.25 0 0 1-.659 1.591A2.3 2.3 0 0 1 18 12.75m2.006-4.5L18.937 4.5H5.065l-1.07 3.75zM15 12.75q.159.212.348.402a3.75 3.75 0 0 0 3.402 1.022V19.5H5.25v-5.326a3.74 3.74 0 0 0 3.402-1.022q.189-.19.348-.402.159.212.348.402A3.75 3.75 0 0 0 15 12.75m-.75-2.25v-.75h-4.5v.75a2.25 2.25 0 0 0 4.5 0m-6-.75v.75A2.25 2.25 0 0 1 6 12.75c-1.21 0-2.25-1.043-2.25-2.25v-.75z"
      clipRule="evenodd"
    />
  </>
);

export default Shop;
