import PointOfInterestType from '@alltrails/analytics/enums/PointOfInterestType';
import SearchItemType from '@alltrails/analytics/enums/SearchItemType';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import SearchSuggestionSelectionType from '@alltrails/analytics/enums/SearchSuggestionSelectionType';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { User } from '@alltrails/context';
import { AlgoliaResultType, SearchType } from '@alltrails/shared/types/algoliaSearch';
import { getLanguageCode } from '@alltrails/shared/utils/languageSupport';
import { POISearchResult } from '../types/algoliaResultTypes';

// IMPORTANT: You MUST not change the order of these. If a new analytics tag is added it
// must be added to the end, and you must coordinate with the other platforms to make sure
// they are using the exact same position and values!
export const getAnalyticsTags = ({
  user,
  isMobile,
  searchOrigin,
  languageRegionCode = 'en-US',
  query
}: {
  user: User | null;
  isMobile?: boolean;
  searchOrigin?: SearchOrigin;
  languageRegionCode: LanguageRegionCode;
  query: string;
}) => {
  const tags = [] as string[];

  if (user) {
    if (user.pro) {
      tags.push('auth:pro');
    } else {
      tags.push('auth:free');
    }
  } else {
    tags.push('auth:none');
  }

  if (isMobile) {
    tags.push('platform:mobileweb');
  } else {
    tags.push('platform:web');
  }

  if (searchOrigin) {
    tags.push(`origin:${searchOrigin}`);
  }

  tags.push('actor:user');
  tags.push(`lang:${getLanguageCode(languageRegionCode)}`);

  if (query && query.length > 0) {
    tags.push('hasquery:true');
  } else {
    tags.push('hasquery:false');
  }

  return tags;
};

export const getSearchSelectionType = (searchType?: SearchType) => {
  switch (searchType) {
    case SearchType.Recent:
      return SearchSuggestionSelectionType.Recent;
    case SearchType.Nearby:
      return SearchSuggestionSelectionType.Nearby;
    default:
      return SearchSuggestionSelectionType.SearchSuggestion;
  }
};

export const getSearchItemType = (resultType: AlgoliaResultType) => {
  switch (resultType) {
    case 'area':
      return SearchItemType.Area;
    case 'attribute':
      return SearchItemType.Attribute;
    case 'filter':
      return SearchItemType.Filter;
    case 'guide':
    case 'page':
      return SearchItemType.Guide;
    case 'list':
      return SearchItemType.List;
    case 'map':
      return SearchItemType.Map;
    case 'place':
      return SearchItemType.Place;
    case 'poi':
      return SearchItemType.Poi;
    case 'track':
      return SearchItemType.Track;
    case 'trail':
      return SearchItemType.Trail;
    default:
      return undefined;
  }
};

export const getPoiType = (result: POISearchResult) => {
  switch (result.subtype) {
    case 'peak':
      return PointOfInterestType.Peak;
    case 'waterfall':
      return PointOfInterestType.Waterfall;
    default:
      return undefined;
  }
};
