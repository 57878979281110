import algoliaSearch, { SearchClient, SearchIndex } from 'algoliasearch';
import { AlgoliaHit, SearchRequestOptions } from '../types/algoliaResultTypes';

type KeysOfUnion<T> = T extends T ? keyof T : never;

// If this list gets too long and starts including fields that aren't a simple literal, consider passing "additional fields" into the AlgoliaSearch entrypoints
export const defaultAttributes: KeysOfUnion<AlgoliaHit>[] = [
  '_geoloc',
  'area_id',
  'area_name',
  'area_slug',
  'area_type',
  'avg_rating',
  'background_color',
  'city_id',
  'city_name',
  'country_id',
  'country_name',
  'description',
  'difficulty_rating',
  'duration_minutes',
  'elevation_gain',
  'filters',
  'guides',
  'has_guides',
  'has_profile_photo',
  'ID',
  'image_url',
  'length',
  'name',
  'name_with_formatting',
  'num_photos',
  'num_reviews',
  'objectID',
  'photo_count',
  'photo_url',
  'popularity',
  'rating',
  'short_name',
  'slug',
  'state_id',
  'state_name',
  'sub_topics',
  'subtype',
  'topic',
  'trail_count',
  'trail_id',
  'type',
  'type_label',
  'location_label'
];

const defaultSearchOptions: SearchRequestOptions = {
  facets: ['type', 'difficulty_rating'],
  clickAnalytics: true,
  attributesToRetrieve: defaultAttributes
};

const defaultFetchOptions: SearchRequestOptions = { attributesToRetrieve: defaultAttributes };

const optimizeOptions = (query: string, options: SearchRequestOptions) => {
  const optimizedOptions = JSON.parse(JSON.stringify(options)) as SearchRequestOptions; // deep clone

  if (!query) {
    optimizedOptions.attributesToHighlight = [];
  } else if (!optimizedOptions.attributesToHighlight) {
    optimizedOptions.attributesToHighlight = ['name'];
  }

  if (!optimizedOptions.responseFields) {
    optimizedOptions.responseFields = ['hits', 'hitsPerPage', 'nbHits'];
  }

  return optimizedOptions;
};

export default class AlgoliaIndex {
  private client: SearchClient;

  private index: SearchIndex;

  constructor(appId: string, apiKey: string, indexId: string) {
    this.client = algoliaSearch(appId, apiKey);
    this.index = this.client.initIndex(indexId);
  }

  search<T = AlgoliaHit>(query: string, options?: SearchRequestOptions) {
    return this.index.search<T>(query, optimizeOptions(query, { ...defaultSearchOptions, ...options }));
  }

  getObject<T = AlgoliaHit>(objectID: string, options?: SearchRequestOptions) {
    return this.index.getObject<T>(objectID, { ...defaultFetchOptions, ...options });
  }

  getObjects<T = AlgoliaHit>(objectIDs: string[], options?: SearchRequestOptions) {
    return this.index.getObjects<T>(objectIDs, { ...defaultFetchOptions, ...options });
  }

  batchSearch(options: SearchRequestOptions[]) {
    return this.client.multipleQueries(
      options.map(o => ({
        indexName: this.index.indexName,
        query: '',
        params: optimizeOptions('', o)
      }))
    );
  }

  clearCache() {
    this.client.clearCache();
  }
}
