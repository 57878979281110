import turfDistance from '@turf/distance';
import { AlgoliaHit } from '../types/algoliaResultTypes';

const createWeightedResultList = (results: AlgoliaHit[], center: [number, number], weightDistance: number) => {
  const scale = weightDistance;
  const decay = 0.5;
  const magicSortFactor = -2 * (scale ** 2 / (2 * Math.log(decay)));
  const centerLngLat = [center[1], center[0]];

  return results
    .map(result => {
      const lng = result?._geoloc?.lng ?? 0;
      const lat = result?._geoloc?.lat ?? 0;
      const popularity = result?.popularity ?? 0;

      const distanceToCenter = turfDistance(centerLngLat, [lng, lat]) * 1000;
      const adjustedPopularity = popularity * Math.exp((-1 * distanceToCenter ** 2) / magicSortFactor);

      return { ...result, adjustedPopularity };
    })
    .sort((a, b) => (b.adjustedPopularity ?? 0) - (a.adjustedPopularity ?? 0));
};

export default createWeightedResultList;
