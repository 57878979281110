import Icon from '../components/Icon';

const Photo = Icon(
  'photo',
  <>
    <path
      fillRule="evenodd"
      d="M12 9.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5M9.75 13a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M9.5 3.25a.75.75 0 0 0-.576.27L6.649 6.25H4A2.75 2.75 0 0 0 1.25 9v9A2.75 2.75 0 0 0 4 20.75h16A2.75 2.75 0 0 0 22.75 18V9A2.75 2.75 0 0 0 20 6.25h-2.649l-2.275-2.73a.75.75 0 0 0-.576-.27zM7.576 7.48l2.275-2.73h4.298l2.275 2.73a.75.75 0 0 0 .576.27h3A1.25 1.25 0 0 1 21.25 9v9A1.25 1.25 0 0 1 20 19.25H4A1.25 1.25 0 0 1 2.75 18V9A1.25 1.25 0 0 1 4 7.75h3a.75.75 0 0 0 .576-.27"
      clipRule="evenodd"
    />
  </>
);

export default Photo;
