import Icon from '../components/Icon';

const PlaceWaterfall = Icon(
  'place-waterfall',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 5.75a2 2 0 0 1 2-2H22a.75.75 0 0 0 0-1.5h-1.25a3.5 3.5 0 0 0-3.5 3.5V16a.75.75 0 0 0 1.5 0V5.75ZM6 19.25a2.25 2.25 0 0 0-2.25 2.25.75.75 0 0 1-1.5 0A3.75 3.75 0 0 1 9 19.25a3.744 3.744 0 0 1 3-1.5c1.227 0 2.316.589 3 1.5a3.75 3.75 0 0 1 6.75 2.25.75.75 0 0 1-1.5 0 2.25 2.25 0 0 0-4.5 0 .75.75 0 0 1-1.5 0 2.25 2.25 0 0 0-4.5 0 .75.75 0 0 1-1.5 0A2.25 2.25 0 0 0 6 19.25Zm8.75-15.5a2 2 0 0 0-2 2V16a.75.75 0 0 1-1.5 0V5.75a3.5 3.5 0 0 1 3.5-3.5H16a.75.75 0 0 1 0 1.5h-1.25Zm-8 2a2 2 0 0 1 2-2H10a.75.75 0 0 0 0-1.5H8.75a3.5 3.5 0 0 0-3.5 3.5V16a.75.75 0 0 0 1.5 0V5.75Z"
    />
  </>
);

export default PlaceWaterfall;
