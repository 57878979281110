import { useEffect } from 'react';
import { useUser } from '@alltrails/context';

const BRAZE_KEY = `${process.env.NEXT_PUBLIC_BRAZE_KEY}`;

const useBraze = (shouldSkipIAM: boolean = false) => {
  const user = useUser();
  const userId = user?.id?.toString();

  useEffect(() => {
    if (BRAZE_KEY) {
      const configureBraze = async () => {
        const { initialize, openSession, automaticallyShowInAppMessages, changeUser } = await import('./braze-exports');
        initialize(BRAZE_KEY, {
          baseUrl: 'https://sdk.iad-03.braze.com',
          allowUserSuppliedJavascript: true
        });

        if (!shouldSkipIAM) {
          automaticallyShowInAppMessages();
        }

        if (userId) {
          changeUser(userId);
        }

        openSession();
      };

      configureBraze();
    }
  }, [userId, shouldSkipIAM]);
};

export default useBraze;
