'use client';

import Expandable from '@alltrails/shared/components/Expandable';
import { COLOR_TEXT_PRIMARY_INVERSE } from '@alltrails/denali/tokens';
import { FooterLinksType } from '../../generateFooterLinks';
import styles from './styles.module.scss';

const ExpandableFooterLink = ({ href, title, amplitudeEventName, childLinks = [] }: FooterLinksType) => (
  <Expandable
    iconColor={COLOR_TEXT_PRIMARY_INVERSE}
    title={
      <a
        data-testid={title}
        className={styles.titleLink}
        href={href}
        onClick={() => {
          if (amplitudeEventName) {
            window.amplitude.track(amplitudeEventName);
          }
        }}
      >
        {title}
      </a>
    }
  >
    <ul>
      {childLinks.map(link => (
        <li key={link.title} className={styles.listItem}>
          <a
            data-testid={link.title}
            href={link.href}
            onClick={() => {
              if (link.amplitudeEventName) {
                window.amplitude.track(link.amplitudeEventName);
              }
            }}
          >
            {link.title}
          </a>
        </li>
      ))}
    </ul>
  </Expandable>
);

export default ExpandableFooterLink;
