import Icon from '../components/Icon';

const Community = Icon(
  'community',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.89 4.5h-.002a5.859 5.859 0 0 0-1.515.204.75.75 0 1 0 .392 1.448c.367-.1.746-.15 1.127-.152a4.125 4.125 0 0 1-.001 8.25h-.01a.75.75 0 0 0 .01 1.5 7.49 7.49 0 0 1 6.136 3.187.75.75 0 1 0 1.227-.862 8.99 8.99 0 0 0-4.245-3.269 5.62 5.62 0 0 0 2.507-4.681A5.625 5.625 0 0 0 15.89 4.5ZM4.126 10.125a4.125 4.125 0 1 1 4.17 4.125h-.091a4.125 4.125 0 0 1-4.079-4.125Zm9.75 0a5.62 5.62 0 0 1-2.507 4.682 8.995 8.995 0 0 1 4.246 3.268.75.75 0 1 1-1.228.862A7.501 7.501 0 0 0 8.3 15.75H8.2a7.5 7.5 0 0 0-6.087 3.187.75.75 0 0 1-1.228-.862 9 9 0 0 1 4.246-3.268 5.625 5.625 0 1 1 8.743-4.682Z"
    />
  </>
);

export default Community;
