import Icon from '../components/Icon';

const Place = Icon(
  'place',
  <>
    <path
      fillRule="evenodd"
      d="M3.75 1.25A.75.75 0 0 1 4.5 2v.75h16.25a.75.75 0 0 1 .58 1.225L17.204 9.02l4.147 5.53a.75.75 0 0 1-.6 1.2H4.5V22A.75.75 0 0 1 3 22V2a.75.75 0 0 1 .75-.75m.75 3v10h14.75l-3.6-4.8a.75.75 0 0 1 .02-.925l3.497-4.275z"
      clipRule="evenodd"
    />
  </>
);

export default Place;
