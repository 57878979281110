import Icon from '../components/Icon';

const Menu = Icon(
  'menu',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6Zm0 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm.75 5.25a.75.75 0 0 0 0 1.5h16.5a.75.75 0 0 0 0-1.5H3.75Z"
    />
  </>
);

export default Menu;
