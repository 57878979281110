import { useCallback, useRef } from 'react';

/**
 * An extension off of the ResizeObserver API with a polyfill for unsupported browsers.
 * @param memoizedCallback [ResizeObserverCallback](https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/ResizeObserver#callback)
 * @returns A tuple containing a function to observe an element and a function to disconnect the observer
 */
export default function useResizeObserver<T extends HTMLElement>(
  memoizedCallback: ResizeObserverCallback
): [(element: T | null | undefined) => void, () => void] {
  const resizeObserverRef = useRef<ResizeObserver>();

  const observe = useCallback(
    (element: T | null | undefined) => {
      if (!element) {
        return;
      }

      const isResizeObserverSupported = 'ResizeObserver' in window;
      if (isResizeObserverSupported) {
        resizeObserverRef.current = new ResizeObserver(memoizedCallback);
        resizeObserverRef.current.observe(element);
      } else {
        console.log('==== ResizeObserver is not supported in this browser ====');
        import('resize-observer-polyfill').then(module => {
          resizeObserverRef.current = new module.default(memoizedCallback);
          resizeObserverRef.current.observe(element);
        });
      }
    },
    [memoizedCallback]
  );

  const disconnect = useCallback(() => resizeObserverRef.current?.disconnect(), []);

  return [observe, disconnect];
}
