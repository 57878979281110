import 'client-only';

export default function setUserProperties(properties: Record<string, any>) {
  const identifyEvent = new window.amplitude.Identify();

  Object.keys(properties).forEach((key: string) => {
    identifyEvent.set(key, properties[key]);
  });

  window.amplitude.identify(identifyEvent);
}
