import Icon from '../components/Icon';

const ChevronSm = Icon(
  'chevron-sm',
  <>
    <path
      fillRule="evenodd"
      d="M12 9.25a.75.75 0 0 1 .53.22l4 4a.75.75 0 1 1-1.06 1.06L12 11.06l-3.47 3.47a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 .53-.22"
      clipRule="evenodd"
    />
  </>
);

export default ChevronSm;
