import Icon from '../components/Icon';

const PlaceMountain = Icon(
  'place-mountain',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.405 3.164a1 1 0 0 0-1.81 0l-4.409 9.405L7.61 7.152a1 1 0 0 0-1.806 0L.493 18.32a1 1 0 0 0 .903 1.43h6.723v-.037c.087.024.178.037.274.037h14.214a1 1 0 0 0 .905-1.425l-7.107-15.16ZM7.523 18.25l1.9-4.053-.1.048-2.616-5.499-4.52 9.504h5.336Zm1.657 0L15.5 4.767l6.32 13.483H9.18Z"
    />
  </>
);

export default PlaceMountain;
