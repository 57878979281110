'use client';

import Script from 'next/script';

const TINUITI_OOH_ADVERTISER_ID = 'nbid12p';
const TINUITI_OOH_PIXEL_IDS = ['b5033m2'];

const oohPageView = () => {
  if (typeof ttd_dom_ready === 'function') {
    ttd_dom_ready(function () {
      if (typeof TTDUniversalPixelApi === 'function') {
        const universalPixelApi = new TTDUniversalPixelApi();
        universalPixelApi.init(TINUITI_OOH_ADVERTISER_ID, TINUITI_OOH_PIXEL_IDS, `https://insight.adsrvr.org/track/up`);
      }
    });
  }
};

export default function TinuitiOOHPixel() {
  // onLoad does not yet work with server components. This file must be a client component.
  // https://nextjs.org/docs/app/api-reference/components/script#onload
  return <Script src="https://js.adsrvr.org/up_loader.1.1.0.js" type="text/javascript" async onLoad={oohPageView}></Script>;
}
