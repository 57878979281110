import Icon from '../components/Icon';

const Location = Icon(
  'location',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.12 2.751a1.5 1.5 0 0 0-.83.047h-.001L3.423 8.29H3.42c-1.385.477-1.32 2.425.05 2.851h.002l7.172 2.213.004.001 2.21 7.172v.002c.426 1.37 2.375 1.435 2.85.05v-.002l5.492-15.866.001-.002a1.5 1.5 0 0 0-1.083-1.958Zm-9.045 9.166L3.916 9.71l-.002-.001 15.869-5.493.003-.001v.003l-5.494 15.87v-.003l-2.21-7.173a1.453 1.453 0 0 0-1.007-.994Z"
    />
  </>
);

export default Location;
