import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import Locale from '@alltrails/shared/types/Locale';
import { getLanguageRegionCodeFromLocale } from '@alltrails/shared/utils/languageSupport';

const namespace = process.env.NEXT_PUBLIC_DEPLOY_NAMESPACE;

export const OsanoLanguageMap = {
  [LanguageRegionCode.English]: 'en',
  [LanguageRegionCode.EnglishGB]: 'en-gb',
  [LanguageRegionCode.Danish]: 'da',
  [LanguageRegionCode.German]: 'de',
  [LanguageRegionCode.Spanish]: 'es',
  [LanguageRegionCode.SpanishLatAm]: 'es-mx',
  [LanguageRegionCode.French]: 'fr',
  [LanguageRegionCode.Italian]: 'it',
  [LanguageRegionCode.Dutch]: 'nl',
  [LanguageRegionCode.Norwegian]: 'nb',
  [LanguageRegionCode.Polish]: 'pl',
  [LanguageRegionCode.Portuguese]: 'pt',
  [LanguageRegionCode.PortugueseBrazilian]: 'pt-br',
  [LanguageRegionCode.Swedish]: 'sv'
};

export const getOsanoLocale = (locale: Locale) => {
  const languageRegionCode = getLanguageRegionCodeFromLocale(locale);
  return OsanoLanguageMap[languageRegionCode] || 'en-gb';
};

export const getOsanoSrc = (locale: Locale) => {
  const osanoLocale = getOsanoLocale(locale);

  if (namespace === 'production') {
    return `https://cmp.osano.com/AzZqakTb9cVIa3Ok9/ca957679-3953-4131-9f80-8e7ef8f97a4f/osano.js?language=${osanoLocale}`;
  }

  return `https://cmp.osano.com/AzZqakTb9cVIa3Ok9/4897ac6f-6a9c-456e-b7e0-5a4b3852f44e/osano.js?language=${osanoLocale}`;
};
