import Icon from '../components/Icon';

const Logout = Icon(
  'logout',
  <>
    <path d="M3.44 3.44A1.5 1.5 0 0 1 4.5 3h5.25a.75.75 0 0 1 0 1.5H4.5v15h5.25a.75.75 0 0 1 0 1.5H4.5A1.5 1.5 0 0 1 3 19.5v-15c0-.398.158-.78.44-1.06" />
    <path d="M9 12a.75.75 0 0 1 .75-.75h8.69l-2.47-2.47a.75.75 0 0 1 1.06-1.06l3.75 3.75a.76.76 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l2.47-2.47H9.75A.75.75 0 0 1 9 12" />
  </>
);

export default Logout;
