import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { cacheSet } from '@alltrails/shared/utils/storage';

const environment = process.env.NEXT_PUBLIC_DEPLOY_NAMESPACE || '';

export const isProduction = () => environment === 'production';

// We need this to fire on the client before any client API calls occur. This *cannot* be async, and it *cannot* be a hook
// When we migrate to using the app router we may be able to do this in a more elegant way. Perhaps using a React context or
// in a way that blocks the page predictably until it's complete
export function setGlobalFetchHeaders({ languageRegionCode = LanguageRegionCode.English }: { languageRegionCode?: LanguageRegionCode } = {}) {
  cacheSet('shared.api.fetch.headers', {
    'X-AT-CALLER': 'Mugen',
    'X-Language-Locale': languageRegionCode
  });
}
