'use client';

import AlgoliaSearchBox from '@alltrails/search/components/AlgoliaSearchBox';
import { AlgoliaConfigs } from '@alltrails/shared/types/algoliaConfigs';
import { useIntl } from 'react-intl';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import styles from './styles/styles.module.scss';

type SearchBoxProps = {
  algoliaConfigs: AlgoliaConfigs;
  placeholder?: string;
};

const SearchBox = ({ algoliaConfigs, placeholder }: SearchBoxProps) => {
  const intl = useIntl();

  return (
    <div className={styles.searchBox}>
      <AlgoliaSearchBox
        configs={algoliaConfigs}
        dropdownVariant="enveloping"
        navigateOnSelect
        onResultSelect={() => {}}
        placeholder={placeholder || intl.formatMessage({ defaultMessage: 'Search' })}
        searchOrigin={SearchOrigin.GlobalNav}
        searchTypes="all"
        testId="header-search"
        variant="subtle"
      />
    </div>
  );
};
export default SearchBox;
