import { MouseEvent } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import ListItemContent from '@alltrails/denali/components/ListItemContent';
import PlusBadge from '@alltrails/denali/components/PlusBadge';
import { AlgoliaSearchResult, NearbyResult } from '../../types/algoliaResultTypes';
import { getLocation, getResultTypeStr, getMapTrackLocation } from '../../utils/algoliaResultUtils';
import getBoldedTextElements from '../../utils/getBoldedTextElements';
import styles from './styles/styles.module.scss';
import ResultIcon from './ResultIcon';

const getDescription = (result: AlgoliaSearchResult, resultTypeLabel?: string, hideResultType?: boolean) => {
  const description =
    result.type === 'list' || result.type === 'guide' || result.type === 'page' || result.type === 'map' || result.type === 'track'
      ? [resultTypeLabel]
      : [];
  switch (result.type) {
    case 'map':
    case 'track':
      description.push(getMapTrackLocation(result));
      break;
    case 'area':
    case 'place':
    case 'poi':
    case 'trail':
      description.push(getLocation(result, hideResultType));
      break;
    case 'list':
      description.push('AllTrails');
      break;
    case 'guide':
      description.push('AllTrails+');
      break;
    case 'page':
      if (result.subtype === 'guides') {
        description.push('AllTrails+');
      }
      break;
    case 'filter':
    default:
      return undefined;
  }
  return description.filter(str => !!str).join(' • ');
};

const isPlus = (result: AlgoliaSearchResult) => result.type === 'guide' || (result.type === 'page' && result.subtype === 'guides');

const onLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
  event.preventDefault();
};

export type AlgoliaSearchResultContentProps = {
  hideResultType?: boolean;
  result: AlgoliaSearchResult | NearbyResult;
  url?: string;
  query: string;
  searchOnEmptyQuery?: boolean;
  getAreaDistance?: (area: AlgoliaSearchResult | NearbyResult) => void;
};

const AlgoliaSearchResultContent = ({ hideResultType, result, url, query, searchOnEmptyQuery, getAreaDistance }: AlgoliaSearchResultContentProps) => {
  const intl = useIntl();

  const algoliaResult = result as AlgoliaSearchResult;
  // Since a poi has a "subtype" that gets displayed, always show the result type for a poi
  const resultType = hideResultType && algoliaResult.type !== 'poi' ? undefined : getResultTypeStr(algoliaResult, intl);
  const isNearby = result.type === 'nearby';
  const label = isNearby ? <FormattedMessage defaultMessage="Nearby" /> : getBoldedTextElements(algoliaResult.name, query);
  const areaDistance = getAreaDistance?.(result);

  const description = isNearby
    ? ''
    : // Since a poi has a "subtype" that gets displayed, always show the result type for a poi
      getBoldedTextElements(getDescription(algoliaResult, resultType, hideResultType && algoliaResult.type !== 'poi') || '', query);
  const testIdSuffix = isNearby ? result.id : algoliaResult.name;

  const icon = (
    <div className={styles.iconContainer}>
      <ResultIcon result={result} />
    </div>
  );

  const content = (
    <ListItemContent
      className={classNames(styles.listItemContent, { [styles.noDescription]: !description })}
      leftContent={!searchOnEmptyQuery && !areaDistance ? icon : undefined}
      label={
        <div className={styles.label}>
          {label}
          {areaDistance ? ` (${areaDistance})` : ''}
        </div>
      }
      description={
        description ? (
          <div className={styles.description}>
            {isPlus(algoliaResult) ? <PlusBadge size="sm" /> : null}
            <span>{description}</span>
          </div>
        ) : null
      }
      testId={`algolia-search-${testIdSuffix}`}
    />
  );

  // The url is just to get link niceties like "open in new tab" behavior - normal navigation must be handled by the onSelect
  return url ? (
    <a href={url} tabIndex={-1} onClick={onLinkClick}>
      {content}
    </a>
  ) : (
    content
  );
};

export default AlgoliaSearchResultContent;
