import { useEffect, useState } from 'react';
import { useFeatures } from '@alltrails/features';
import { useExperiments } from '@alltrails/experiments';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { useSelector } from 'react-redux';
import { getAFOneLinkConfig } from '../utils/getAFOneLinkConfig';

/*
  useDeeplink creates deeplinks for location and trail pages to the app, defaulting to the homepage.
*/

const oneLinkFallback = 'https://alltrails.onelink.me/EFvD/hvsmar15';
const branchLinkFallback = 'https://alltrails.io/rgGVELw1wCb';

type UseDeepLinkParams = {
  pageType?: 'area' | 'trail';
  pageId?: number;
  isOpen?: boolean;
  promptType?: 'banner' | 'interstitial';
  displayTrigger?: CarouselDisplayTrigger;
};

const useDeeplink = ({ pageType, pageId, isOpen, promptType, displayTrigger }: UseDeepLinkParams) => {
  const isAppsFlyerEnabled = useFeatures().includes('appsflyer_migration');
  const experiments = useExperiments();
  const browserName = useSelector((state: { browser?: UserAgentBrowser }) => state.browser?.name);

  const [deeplink, setDeeplink] = useState(isAppsFlyerEnabled ? oneLinkFallback : branchLinkFallback);

  useEffect(() => {
    const branchLinkBase = 'https://alltrails-alternate.app.link';

    let appPath = `${isAppsFlyerEnabled ? '' : 'alltrails://'}screen/explore`;

    if (pageType && pageId) {
      appPath = `${isAppsFlyerEnabled ? '' : 'alltrails://'}${pageType}/${pageId}`;
    }

    if (isAppsFlyerEnabled) {
      if (isOpen) {
        const createOneLink = () => {
          if (window.AF_SMART_SCRIPT) {
            const resultUrl = window.AF_SMART_SCRIPT.generateOneLinkURL(getAFOneLinkConfig(appPath));
            if (resultUrl?.clickURL) {
              setDeeplink(resultUrl.clickURL);

              // Timeout required according to AF documentation.
              // https://dev.appsflyer.com/hc/docs/dl_smart_script_v2#fire-an-impression
              setTimeout(() => {
                window.AF_SMART_SCRIPT.fireImpressionsLink();
              }, 1000);
            }
          }
        };

        if (window.AF_SMART_SCRIPT) {
          createOneLink();
        } else {
          const onelinkScript = document.querySelector('#af-onelink-script');
          if (onelinkScript) {
            onelinkScript.addEventListener('load', () => {
              if (window.AF_SMART_SCRIPT) {
                createOneLink();
              }
            });
          }
        }
      }
    } else {
      let displayTriggerParam = '';

      const params = new URLSearchParams();

      if (promptType) {
        params.append('feature', `native_${promptType}`);
      }

      ['web-growth-rm-app-install-on-auth-wall'].forEach(key => {
        const value = experiments[key]?.value;

        if (value) {
          params.append(key, value);
        }
      });

      if (browserName) {
        params.append('browser', browserName);
      }

      const search = params.toString();
      const experimentParams = search ? `&${search}` : '';

      if (displayTrigger) {
        displayTriggerParam = `&creative_name=${displayTrigger}`;
      }

      // Branch long-link isn't built using URLParams to prevent serializing deeplink paths.
      // eslint-disable-next-line max-len
      const branchDeeplink = `${branchLinkBase}?type=install_prompt&$ios_deeplink_path=${appPath}&$android_deeplink_path=${appPath}${experimentParams}${displayTriggerParam}`;
      setDeeplink(branchDeeplink);
    }
  }, [isAppsFlyerEnabled, pageId, pageType, isOpen, promptType, displayTrigger, experiments]);

  return deeplink;
};

export default useDeeplink;
