import { MessageDescriptor, useIntl } from '@alltrails/shared/react-intl';
import { type PrimitiveType, type FormatXMLElementFn } from 'intl-messageformat';

type DefinedMessages = Record<string, MessageDescriptor>;

type FormattedMessages = {
  [key: keyof DefinedMessages]: string;
};

type Messages = {
  formattedDefaultMessages: FormattedMessages;
  formatMessage: (key: keyof DefinedMessages, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) => string;
};

/**
 * A hook to help format strings given an object of defined messages.
 * @param definedMessages Object of defined messages, defined by react-intl's defineMessages() func
 * @returns an object with formatted strings & a function for any formatting overrides needed
 */
function useFormatMessage(definedMessages: DefinedMessages): Messages {
  const intl = useIntl();

  const formattedMessages = {} as FormattedMessages;
  Object.keys(definedMessages).forEach(messageKey => {
    formattedMessages[messageKey] = intl.formatMessage(definedMessages[messageKey]);
  });

  return {
    formattedDefaultMessages: formattedMessages,
    formatMessage: (key: keyof DefinedMessages, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) =>
      intl.formatMessage(definedMessages[key], values)
  };
}

export { useFormatMessage as default };
export type { FormattedMessages };
