import Icon from '../components/Icon';

const Bookmark = Icon(
  'bookmark',
  <>
    <path
      fillRule="evenodd"
      d="M4.25 5A2.75 2.75 0 0 1 7 2.25h10A2.75 2.75 0 0 1 19.75 5v16a.75.75 0 0 1-1.122.651L12 17.864 5.372 21.65A.75.75 0 0 1 4.25 21zM7 3.75c-.69 0-1.25.56-1.25 1.25v14.708l5.878-3.36a.75.75 0 0 1 .744 0l5.878 3.36V5c0-.69-.56-1.25-1.25-1.25z"
      clipRule="evenodd"
    />
  </>
);

export default Bookmark;
