'use client';

import { ReactNode } from 'react';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';

type ConditionalRendererProps = {
  children: ReactNode;
  renderWhen: 'desktop' | 'mobile';
};

const ConditionalRenderer = ({ children, renderWhen }: ConditionalRendererProps) => {
  const isMobile = useIsMobileSizedScreen();

  if (renderWhen === 'mobile') {
    return isMobile ? children : null;
  } else {
    return isMobile ? null : children;
  }
};

export default ConditionalRenderer;
