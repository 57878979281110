import Icon from '../components/Icon';

const Heart = Icon(
  'heart',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.691 4.58a4.125 4.125 0 0 1 4.617 2.458.75.75 0 0 0 1.384 0 4.125 4.125 0 0 1 7.933 1.587c0 2.842-2.116 5.555-4.44 7.646a28.858 28.858 0 0 1-4.172 3.1L12 19.38l-.013-.008a28.858 28.858 0 0 1-4.173-3.1c-2.323-2.09-4.439-4.804-4.439-7.646A4.125 4.125 0 0 1 6.691 4.58Zm4.942 16.324L12 20.25l-.367.654a.75.75 0 0 0 .734 0L12 20.25l.367.654.002-.001.007-.004.023-.013.084-.049.31-.184a30.34 30.34 0 0 0 4.396-3.267c2.365-2.129 4.936-5.228 4.936-8.761A5.625 5.625 0 0 0 12 5.25 5.625 5.625 0 0 0 1.875 8.625c0 3.533 2.571 6.632 4.936 8.76a30.343 30.343 0 0 0 4.79 3.501l.023.013.006.004h.002l.001.001Z"
    />
  </>
);

export default Heart;
