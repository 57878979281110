'use client';

import { FormattedMessage } from 'react-intl';
import Button from '@alltrails/denali/components/Button';
import styles from './styles/styles.module.scss';

const SkipToMainContentButton = () => {
  return (
    <Button
      className={styles.skipToMainContentButton}
      linkInfo={{ href: '#main-content' }}
      text={<FormattedMessage defaultMessage="Skip to main content" />}
      testId="header-skip-to-main-content"
      variant="primary"
    />
  );
};
export default SkipToMainContentButton;
