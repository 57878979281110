import classNames from 'classnames';
import { useMemo } from 'react';
import { Typography } from '@alltrails/core';
import { DropdownMenuTextOption } from '../../types';
import LinkButtonOrDiv from '../LinkButtonOrDiv';
import Check from '../../icons/Check';
import IconRenderer from '../IconRenderer';
import styles from './styles/styles.module.scss';

type DropdownMenuItemProps = {
  option: DropdownMenuTextOption;
  textVariant: 'text100' | 'text200';
};

const DropdownMenuItem = ({ option, textVariant }: DropdownMenuItemProps): JSX.Element => {
  const { className, leadingIcon, linkInfo, onClick, selected, subtext, testId, title, trailingIcon } = option;

  const leadingIconElement = useMemo(() => {
    if (leadingIcon) {
      const { background, customElement, icon } = leadingIcon;
      const iconElement = icon ? <IconRenderer icon={icon} defaults={{ color: '--color-text-primary', size: 'sm' }} /> : customElement;
      return background ? <div className={styles.iconBackground}>{iconElement}</div> : iconElement;
    }
    return null;
  }, [leadingIcon]);

  const trailingIconElement = useMemo(() => {
    // differentiate undefined vs false for "selected" as a way to determine if room should be saved for a selection icon
    if (selected === true || selected === false) {
      return <div className={styles.iconContainer}>{selected ? <Check size="sm" /> : null}</div>;
    }
    if (trailingIcon) {
      return trailingIcon.icon ? (
        <IconRenderer icon={trailingIcon.icon} defaults={{ color: '--color-text-primary', size: 'sm' }} />
      ) : (
        trailingIcon.customElement
      );
    }
    return null;
  }, [selected, trailingIcon]);

  return (
    <LinkButtonOrDiv key={testId} className={classNames(styles.option, className)} linkInfo={linkInfo} onClick={onClick} testId={testId}>
      {leadingIconElement}
      <div className={styles.text}>
        <Typography className={styles.title} variant={textVariant}>
          {title}
        </Typography>
        {subtext && (
          <Typography color="secondary" variant="text100">
            {subtext}
          </Typography>
        )}
      </div>
      {trailingIconElement}
    </LinkButtonOrDiv>
  );
};

export default DropdownMenuItem;
