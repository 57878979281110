'use client';

import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss'

const ManageCookies = () => {
  const [isOsanoAvailable, setIsOsanoAvailable] = useState(false);

  useEffect(() => {
    if (window.Osano?.cm?.mode === 'production' || window.Osano?.cm?.mode === 'permissive') {
      setIsOsanoAvailable(true);
    }
  }, []);

  const handleManageCookiesClick = () => {
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  };

  if (!isOsanoAvailable) {
    return <></>;
  }

  return (
    <>
      •
      <button type="button" onClick={handleManageCookiesClick} className={styles.manageCookiesBtn}>
        <FormattedMessage defaultMessage="Manage Cookies" />
      </button>
    </>
  );
};

export default ManageCookies;
